import { render, staticRenderFns } from "./StudentFreight.vue?vue&type=template&id=774c10fc&scoped=true&"
import script from "./StudentFreight.vue?vue&type=script&lang=js&"
export * from "./StudentFreight.vue?vue&type=script&lang=js&"
import style0 from "./StudentFreight.vue?vue&type=style&index=0&id=774c10fc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "774c10fc",
  null
  
)

export default component.exports